<template>
  <div >





    <!-- Contact Us Form-->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="text-center mb-5">
        <div class="custom-widget mt-2 ">
          <h2 class=" ">Contact Us</h2>
        </div>
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="bag-plus"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>

      </div>
      <div class="text-center mb-5">

      </div>
      <div class="row align-items-center">
        <div class="col-lg-1"></div>
        <div class="col-lg-5">
          <form autocomplete="off">
            <div class="form-row">
              <div class="form-group col-md-6">

                <input type="text" v-model="userModel.fullName" class="form-control custom-input-gotopage custom-job-search-input" id="fullName" placeholder="Full Name">
              </div>
              <div class="form-group col-md-6">

                <input type="email" v-model="userModel.email" class="form-control custom-input-gotopage custom-job-search-input" id="inputEmail" placeholder="Email">
              </div>
            </div>
            <div class="form-group">

              <input type="text" v-model="userModel.contactNumber" class="form-control custom-input-gotopage custom-job-search-input" id="contactNo" placeholder="Contact No">
            </div>
            <div class="form-group">

              <textarea type="text" v-model="userModel.notes" class="form-control custom-input-gotopage custom-job-search-input pt-2 pb-2" rows="5" id="notes" placeholder="Notes" maxlength="2000"></textarea>
            </div>

            <div class="pt-3"></div>
            <!--  Load more    -->
            <div>
              <div class="text-center clear-bottom">
                <button type="button" @click="submitForm()" class="btn btn-outline-secondary btn-theme">

                  SUBMIT

                </button
                >
              </div>
            </div>
          </form>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-4" style="margin-top: -16%;">
          <div class="text-center ">
            <div class="custom-widget mt-2 ">
              <h3 class="text-uppercase"></h3>
            </div>


          </div>
<!--          <div  class="mt-2 mb-2 custom-row">
            <span class="mr-5" >
              <div class="text-center">
                <div class="custom-card-icon custom-contact-icon">
                  <b-icon class="custom-contact-icon-style"  icon="geo-alt-fill"></b-icon>
                </div>

             >

            <div class="flex-grow-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="widget-title">Our Location</h3>
              </div>


              <p class="custom-service-text">
                23 The Mall Road Lahore Pakistan
              </p>
            </div>






          </div>-->
          <div  class="mt-2 mb-2 custom-row">
            <span class="mr-5" >
              <div class="text-center">
                <div class="custom-card-icon custom-contact-icon">
                  <b-icon class="custom-contact-icon-style" icon="telephone-fill"></b-icon>
                </div>

              </div>
              <!--                      <img :src="url+job.employeeImage" alt=""
              class="w3-left w3-circle w3-margin-right"
              style="width: 60px; height: 60px">-->
            </span>

            <div class="flex-grow-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="widget-title">Call Us On</h3>
              </div>


              <p class="custom-service-text">
                (+92) 332 9777738<br>
                (+92) 423 7243810
              </p>
            </div>

          </div>
          <div  class="mt-2 mb-2 custom-row">
            <span class="mr-5" >
              <div class="text-center">
                <div class="custom-card-icon custom-contact-icon ">
                  <b-icon class="custom-contact-icon-style" icon="envelope-fill"></b-icon>
                </div>

              </div>
              <!--                      <img :src="url+job.employeeImage" alt=""
              class="w3-left w3-circle w3-margin-right"
              style="width: 60px; height: 60px">-->
            </span>

            <div class="flex-grow-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="widget-title">Send Your Message</h3>
              </div>


              <p class="custom-service-text">
                info@shemeanswork.com
              </p>
            </div>

          </div>
        </div>
        <div class="col-lg-1"></div>
      </div>


    </div>




  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'ContactUs',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {
      url: axios.defaults.baseURL,
      userModel:{
        fullName: "",
        email: "",
        contactNumber: "",
        notes: ""
      },
    }
  },
  //carousel_latest_jobs
  methods: {
    submitForm() {
      this.info = [];

      if (
        this.userModel.fullName != "" &&
        this.userModel.contactNumber != ""  &&
        this.userModel.email != ""  &&
        this.userModel.notes != ""
      ) {


        console.log("Employee : ", this.userModel);
        axios
          .post("/api/Shehri/SMWContactForm", this.userModel)
          .then(res => {
            // code that we will 'try' to run
         
            console.log("Add Response portion");

            console.log("response : ", this.info);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {
              this.userModel.fullName = "";
              this.userModel.contactNumber = "";
              this.userModel.email = "";
              this.userModel.notes = "";
              this.$bvToast.toast("Thanks for contacting us! We will be in touch with you shortly.", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });

            }
          });
      } else {
        this.isValidated = true;
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });

      }





    },
  },

  mounted () {

  }
}
</script>
