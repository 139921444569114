<template>
  <header  class="custom-mainmenu" :class="[!$auth.check() ? '':'', $auth.check() ? 'sticky-top':'']">
    <div class="custom-header">
      <div class="">
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light">
          <div
              class="navbar-collapse custom-multi-navbar"
              id="headerSupportedContent"
          >
            <template v-if="$auth.check()" >
                <div v-if="$auth.user().user.role == 'Employer'"  class="navbar mr-auto w-100 pl-0 pr-0">
                <ul  class="navbar-nav mr-auto">
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-dashboard">
                      Dashboard
                    </router-link
                    >
                  </li>
                  <li class="nav-item dropdown mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myJobsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      My Jobs
                    </a>
                    <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myJobsDropdown">
                      <router-link class="dropdown-item" to="/employer-jobs">All Listed Jobs</router-link>
                      <router-link class="dropdown-item" to="/employer-candidates/0">All Employee Profile</router-link>
                    </div>
                  </li>
                  <li class="nav-item dropdown mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myToolsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      My Tools
                    </a>
                    <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myToolsDropdown">
                      <router-link class="dropdown-item" to="/employer-profile">Update Company Profile</router-link>
                      <router-link class="dropdown-item" to="/change-password">Account Settings</router-link>
                      <router-link class="dropdown-item" to="/employer-packagehistory">Purchase History </router-link>
                      <router-link class="dropdown-item" to="/employer-package">Packages </router-link>
                    </div>
                  </li>
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/cv-search">
                      CV Search
                    </router-link>
                  </li>
                  <!-- <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-postjob/0">
                      Post A Job
                    </router-link>
                  </li> -->
                </ul>
              </div>
              <div v-else class="navbar mr-auto w-100 pl-0 pr-0">
                <ul  class="navbar-nav mr-auto">
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employee-dashboard">
                      Dashboard
                    </router-link
                    >
                  </li>
                  <li class="nav-item dropdown mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      My Account
                    </a>
                    <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                      <router-link class="dropdown-item" to="/employee-profile">Profile</router-link>
                      <router-link class="dropdown-item" to="/employee-jobs">Applications</router-link>
                      <router-link class="dropdown-item" :to="{ name: 'ChangePassword'}">Change Password</router-link>
                    </div>
                  </li>
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/add-profile-detail">
                      Update Your Profile
                    </router-link>
                  </li>
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employee-jobs">
                      My Applications
                    </router-link>
                  </li>
                </ul>
              </div>
            </template>
          </div>
          <div></div>
        </nav>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "EmployerMenu",
  components:{
  },
  data(){
    return{
    };
  },
  computed:{
  },
  mounted(){
  },
  methods: {
  },
};
</script>
<style>
</style>
