<template>

  <div class="container">
   

     <div class="row mt-30">
        <div class="style-spacer"></div>
      <div class="col-md-12  ">
         

        

                  <div
              class="w3-card-2 pt-3 pb-3 ml-auto mr-auto"
              style=" "
            >
              <header class="w3-container custom-row">
                <h3>Team Members</h3>
               
               
              </header>
              <hr />
              <div class="w3-container custom-row">
                
               <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Email </th>
      <th scope="col">Role</th>
      <th scope="col">Department</th>
      <th scope="col">Action</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Younas </td>
      <td>younas@itroos.net</td>
      <td>Admin</td>
      <td>Admin</td>
      <td> <a href=""> Deactivate</a></td>
      
      
    </tr>
      <tr>
      <th scope="row">1</th>
      <td>Osama </td>
      <td>osama@itroos.net</td>
      <td>Editor</td>
      <td>HR</td>
      <td> <a href=""> Deactivate</a></td>
      
      
    </tr>

     <tr>
      <th scope="row">1</th>
      <td>Danish </td>
      <td>danish@itroos.net</td>
      <td>Editor</td>
      <td>Admin</td>
      <td> <a href=""> Deactivate</a></td>
      
      
    </tr>
     
 
  </tbody>
</table>

            </div>
            <p>
 <router-link  style="
    color: #e62e6b;
    margin-left: 15px;
" class="" to="/employer-addteam">
                  Add Team
                </router-link>
</p>
      </div>
 
    </div>

    <div class="style-spacer"></div>
  </div>
  </div>
</template>
 

<script>
import axios from "axios";
export default {
  name: "TeamList",  components: {},
  data() {
    return { 
      has_error: false,
      isDataFetched : false,
      isLoading: false,
              EmployerId: this.$auth.user().employeeId,
     

      body: {
        employerCompanyId: 0,
        companyEmail: null,
        companyName: null,
        ceoName: null,
        employeeId: this.$auth.user().employeeId,
        industryId: 0,
        ownerShipTypeId: 0,
        companyDesciption: null,
        countryId: 106,
        cityId: 0,
        address: null,
        establishedInYear: 0,
        websiteUrl: null,
        contactNumber: null,
        googleMapIframe: null
      }
    };
  },
  computed: {},
  mounted() {
    // /EmployerCompany/GetEmployerCompany
    //this.getProfileInfo()
   

  }, 
  methods: {
     getProfileInfo(){
      axios.get('/api/EmployerJobPostPackage/GetAllJobPostPackagesByEmployeeId')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            let resdata = res.data.data;
            console.log("res.data : ",  res.data );


            if(res.data == 0){
              this.body.employerCompanyId = resdata.employerCompanyId
              this.body.industryId = resdata.industryId
              this.body.cityId = resdata.cityId
              this.body.ownerShipTypeId = resdata.ownerShipTypeId
              this.body.countryId = resdata.countryId
              this.body.establishedInYear = resdata.establishedInYear
              this.body.companyEmail = resdata.companyEmail
              this.body.companyName = resdata.companyName
              this.body.ceoName = resdata.ceoName
              this.body.companyDesciption = resdata.companyDesciption
              this.body.address = resdata.address
              this.body.websiteUrl = resdata.websiteUrl
              this.body.contactNumber = resdata.contactNumber
              this.body.googleMapIframe = resdata.googleMapIframe
            }
            else {
               
            }
            this.isDataFetched = true

            // });
          }).catch(error => {
        console.log(error);
      })
          .finally(() => {
           
          });
    },
    postData() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.industryId = Number(this.body.industryId)
      this.body.cityId = Number(this.body.cityId)
      this.body.ownerShipTypeId = Number(this.body.ownerShipTypeId)
      this.body.countryId = Number(this.body.countryId)
      this.body.establishedInYear = Number(this.body.establishedInYear)
     
       console.log(this.body)

       axios
            .post("/api/EmployerCompany/InsertEmployerCompany", this.body)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              this.isLoading = false
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.$bvToast.toast("Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: "Company Profile",
                  solid: true
                });
                /*if(this.selectedCategory==1){
                  this.$router.push({name:'Graduate'});
                }
                else if(this.selectedCategory==2){
                  this.$router.push({name:'MumsReturntoWork'});
                }
                else if(this.selectedCategory==3){
                  this.$router.push({name:'SpecialneedPerson'});
                }
                else if(this.selectedCategory==4){
                  this.$router.push({name:'LabourManPower'});
                }*/

              }
            });



    },
  },
};

</script>
<style>
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    border: 1px solid #8a8e9e;
    background: #1a1f31;
    color: white;
}
</style>