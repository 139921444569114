<template>
  <div class="container">
            <div class="mt-2">
 

      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="employee-detail-tab"
                  data-toggle="tab"
                  href="#employee-detail"
                  role="tab"
                  aria-controls="employee-detail"
                  aria-selected="true"
              >
                <span>Add A Team Member </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
        <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content cusform" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="employee-detail"
            role="tabpanel"
            aria-labelledby="employee-detail-tab"
        >
    <div class="  mt-30">
      <div class="w3-card">
        <div class="card-body">
          <!-- <h2>Job Details</h2> -->
          <form class="custom-validation" 
          
              @submit.prevent="postjob"
              method="post" novalidate="">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Name</label
                >
                <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  required=""
                  id="txtfname"
                  v-model="body.jobTitle"
                  name="txtfname"
                  placeholder="Name"
                />
              </div>
              </div>
              </div>
                  
                    <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Email</label
                >
                <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  required=""
                  id="txtfname"
                  
                  name="txtfname"
                  placeholder="Email"
                />
              </div>
              </div>
              </div>
                  
            </div>
            
            <div class="row mt-3">
           
            </div>
            
            <div class="row mt-3">
            <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Member Role</label
                >
                <div class="col-sm-9">
                <select class="form-control" required=""  >
                  <option value="">Select Option</option>
                  <option value="1">Admin </option>
                  <option value="2">Editor</option> 
                </select>
              </div>
              </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Department</label
                >
                <div class="col-sm-9">
                <select class="form-control" required=""  >
                  <option value="">Select Option</option>
                  <option value="1">HR </option>
                  <option value="2">Admin</option> 
                </select>
              </div>
              </div>
              </div>
            </div>
            <div class="row mt-3">
            
              <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">City</label
                >
                <div class="col-sm-9">
                <select class="form-control" required="" v-model="body.cityId">
                  <option value="">Select Option</option>
                  <option value="1">Lahore</option>
                  <option value="2">Islamabad</option>
                  <option value="3">Karachi</option>
                </select>
              </div>
              </div>
              </div>
            </div>
           
            <hr>
               <div class="modal-footer" style=" width: 100%;
    border-top: 0px;">
                      <div>
                        <b-button
                            id="resetbtn"
                            class="form-control custom-form-btn custom-btn-gotopage"
                             
                            type="reset"

                            variant="outline-dark"
                        >Reset
                        </b-button>
                      </div>

                      <div>
                        <b-button type="submit" 
                            class="form-control custom-form-btn custom-btn-gotopage"
                           :disabled="isLoading" 

                            variant="outline-dark"
                        >Save
                        </b-button>
                      </div>
                    </div>
            <!-- <div class="text-end mt-5">
              <button
                type="submit"
               
              >
                Submit</button
              ><button type="reset" class="btn btn-secondary waves-effect">
                Cancel
              </button>
            </div> -->
          </form>
        </div>
      </div>
    </div>
        </div>
      </div>
    </div>
    <div class="style-spacer"></div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddTeam",  components: {},
  data() {
    return {
      userName:null,
      emailAddress: null,
      password: null,
      phoneNo:null,
      selectedUserRole:null,
      has_error: false,
      isLoading: false,
      EmployeerId: 8,
      jobTitle:  null,
      jobDetail:  null,
      countryId:  106,
      cityId:  2,
      packageFrom:  null,
      packageTo:  null,
      noOfJobs:  null,
      preference:  null,
      jobExpiryDate:  null,
      degreeLevelId:  null,
      requiredJobExperience:  null,
      jobTypeId:  null,
      jobSubTypeId:  null,
      jobTypeDetailId:  null,
      weeklyWorkingHour:  null,
      workLocation:  null,
      travelMiles:  null,
      travelTimePercent:  null,
      projectTitle:  null,
      estimatedDurationInMonth:  null,
      projectDescription:  null,
      isSignLanguageRequrired:  null,
      disabilityTypeId:  null,
      jobHiringType:  null,
      assistedHiringType:  null,
      employerJobPostPackageId:  2,

      body: {
              EmployerId: this.$auth.user().employeeId,
      jobTitle:  null,
      jobDetail:  null,
      countryId:  106,
      cityId:  2,
      packageFrom:  null,
      packageTo:  null,
      noOfJobs:  null,
      preference:  null,
      jobExpiryDate:  null,
      degreeLevelId:  null,
      requiredJobExperience:  null,
      jobTypeId:  null,
      jobSubTypeId:  null,
      jobTypeDetailId:  null,
      weeklyWorkingHour:  2,
      workLocation:  2,
      travelMiles:  1,
      travelTimePercent:  1,
      projectTitle:  "dwe",
      estimatedDurationInMonth:  1,
      projectDescription:  "asd",
      isSignLanguageRequrired:  1,
      disabilityTypeId:  1,
      jobHiringType:  1,
      assistedHiringType:  1,
      employerJobPostPackageId:  2
      }
    };
  },
  computed: {},
  mounted() {
    //

   

  }, 
  methods: {
 
    postjob() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.countryId = Number(this.body.countryId)
      this.body.cityId = Number(this.body.cityId)
      this.body.packageFrom = Number(this.body.packageFrom)
      this.body.packageTo = Number(this.body.packageTo)
      this.body.noOfJobs = Number(this.body.noOfJobs)
      this.body.preference = Number(this.body.preference)
      this.body.degreeLevelId = Number(this.body.degreeLevelId)
      this.body.requiredJobExperience = Number(this.body.requiredJobExperience)
      this.body.jobTypeId = Number(this.body.jobTypeId)
      this.body.jobSubTypeId = Number(this.body.jobSubTypeId)
      this.body.jobTypeDetailId = Number(this.body.jobTypeDetailId)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
       console.log(this.body)

       axios
            .post("/api/Job/InsertNewJob", this.body)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              this.isLoading = false
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.$bvToast.toast("Posted Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: "Job",
                  solid: true
                });
                /*if(this.selectedCategory==1){
                  this.$router.push({name:'Graduate'});
                }
                else if(this.selectedCategory==2){
                  this.$router.push({name:'MumsReturntoWork'});
                }
                else if(this.selectedCategory==3){
                  this.$router.push({name:'SpecialneedPerson'});
                }
                else if(this.selectedCategory==4){
                  this.$router.push({name:'LabourManPower'});
                }*/

              }
            });



    },
  },
};

</script>