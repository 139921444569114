<template>
  <div :key="componentKey">
    <form
        v-if="show"
        autocomplete="off"
    >
      <div>
        <a v-if="addSkill_btn"
           href="javascript:void(0)"

           @click="addSkill()"
           title="Add Skill"

        >
          <div class="custom-icon icon">
            <plus-icon size="2x" class="custom-class"></plus-icon>

          </div>
        </a>
      </div>
      <div class="row" v-for="(skill, index) in skills" :key="index">
        <div class="col-md-5">
          <div class="mb-3 form-group row no-gutters" v-if="skillName_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="skillName_id">Skill Name</label>
            <div class="col-sm-9">
              <select id="skillName_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="skill.skillsSubCategoryId"
              >
                <option value = 0>Please Select Skill</option>
                <option
                    v-for="option in skillSubCategories"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>

            </div>

          </div>



        </div>
        <div class="col-md-5">
          <div class="mb-3 form-group row no-gutters" v-if="skillLevel_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="skillLevel_id">Skill Level</label>
            <div class="col-sm-9">
              <select id="skillLevel_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="skill.skillLevelOutof100"
              >
                <option value= 0 >Please Select Skill Level</option>
                <option
                    v-for="option in skillLevels"
                    :key="option.value"
                    :value="option.text"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>

          </div>
        </div>
        <div class="col-md-2">
          <div class="">
            <a
                v-if="removeSkill_btn"
                href="javascript:void(0)"

                @click="removeSkill()"
                title="Remove Skill"

            >
              <b-icon font-scale="1" variant="danger" icon="trash"></b-icon
              >

            </a>
          </div>
        </div>
      </div>
      <div class="modal-footer">

        <button type="button" id="skillbutton" class="form-control form-control-sm custom-form-btn custom-btn-gotopage" data-toggle="modal" data-target="#exampleModal">
  Add new skills
</button>
        <div v-if="showCloseButton">
          <b-button
              id="showCloseButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              type="button"
              size="sm"
              @click="close"
              variant="outline-dark"
              data-dismiss="modal"
          >Close
          </b-button>
        </div>
        <div v-if="showResetButton">
          <b-button
              id="showResetButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              type="reset"
              size="sm"
              variant="outline-dark"
          >Reset
          </b-button>
        </div>
        <div>
          <b-button

              v-if="showSaveButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              @click="AddOrUpdate()"
              size="sm"
              variant="outline-dark"
          >Save
          </b-button>
        </div>
        <!-- <div>
          <b-button
            v-if="showReassignButton"
            class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
            @click="AddOrUpdate()"
            size="sm"
            variant="outline-dark"
            >Reassign
          </b-button>
        </div> -->
      </div>
    </form>
    <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add New Skill</h5>
        <!-- <span class="close" @click="closeModal">
          <span aria-hidden="true">&times;</span>
        </span> -->
      </div>
      <div class="modal-body">
        <form ref="AddDropdownForm">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Text" label-for="Text" autocomplete="off">
              <b-form-input
                id="Text"
                v-model="Text"
                :class="{'is-invalid':submitted && $v.Text.$error}"
                type="text"
                required
              ></b-form-input>
             
            </b-form-group>
          </div>
        </div>
        <span v-if="displaymessage == true">Skill added please click outside of modal to close.</span>

      </form>
      </div>
      <div class="modal-footer">
        <!-- <span class="btn btn-primary close" @click="closeModal">Close</span> -->
        <button type="button" @click="AddDropdownValue" class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</div>
    <!--    <CPModal :is-experience-section-visible="isExperienceSection"></CPModal>-->
  </div>
</template>
<script>
import axios from "axios";
import { PlusIcon } from "vue-feather-icons";
//import CPModal from "@/components/employeeComponents/CompleteProfileModalComponent";
export default {
  name: 'AddEditSkillsComponent',
  components:{
    PlusIcon,
    // CPModal
  },
  data(){
    return{

      displaymessage:false,
      Text: null,
      employeeId:0,
      //Skill Section Start
      isSkillSection:true,
      skillName_dd:true,
      skillLevel_dd:true,
      addSkill_btn:false,
      removeSkill_btn:false,

      skills:[{empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0}],
      skillSubCategories:null,
      selectedSkillLevel:0,
      skillLevels:[
        { value: 1, text: '10'},
        { value: 2, text: '20'},
        { value: 3, text: '30'},
        { value: 4, text: '40'},
        { value: 5, text: '50'},
        { value: 6, text: '60'},
        { value: 7, text: '70'},
        { value: 8, text: '80'},
        { value: 9, text: '90'},
        { value: 10, text: '100'},

      ],


      empSkillId:0,
      skillsSubCategoryId:0,
      skillLevelOutof100:0,
      //form
      show: true,
      showCloseButton: true,
      showResetButton: true,
      //showReassignButton: false,
      showSaveButton: true,


      employeelist:null,

      employeeExperience:null,




      info:null,
      rowid:null,
      loading: true,
      errored: false,
      componentKey: 0,
    };
  },
  validations: {
    
  },
  props:{
    title:{
      type: String
    },
    skillModal:{
      type: Boolean
    },
    emprecord:{
      type: Object
    }
  },
  computed: {



  },
  methods:{
    close(){
      this.$emit('toggle-modal');
      console.log("closetriggerrd")


    },
    AddDropdownValue() {
      axios
        .post("/api/keyvaluepair/AddDropdown", {
          value: this.Text,
          type: 'SkillCategory',
        })
        .then((res) => {
          if (res.data != null) {
            this.displaymessage = true;
            this.getSubCategories();
          }
        });
    },
    resetPage(){
      this.$emit('reset-page');
      console.log("resettriggerrd")


    },
    skillSectionState(value){
      this.isSkillSection = value;
      this.$emit('change-state', this.isSkillSection);
      console.log("state triggered")
    },
    getAll(){
      this.rerender();
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);
            this.employeeId = this.employeelist.employeeId;


          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
   

    },
    getSubCategories() {

      this.skillSubCategories = [];
      let model = {
        type: "SkillCategory"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run


            this.skillSubCategories = res.data.data;


            console.log("Add Response portion");

            console.log("skillSubCategories : ", this.skillSubCategories);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },


    AddOrEditModal(){
      this.rerender();
      if(this.emprecord != null && this.emprecord != ""){
        console.log("Not Null: ",this.emprecord);
        this.EditModalSkill(this.emprecord);

      }
      else{

        console.log("Null: ",this.emprecord);
        this.NewModalSkill();

      }
    },







    //Add Modal Skill
    NewModalSkill() {
      this.showCloseButton = true;
      this.showResetButton = true;
      this.reset();


      this.showSaveButton = true;
      this.addSkill_btn = true;
      this.removeSkill_btn = true;

    },
    //EDIT MODAL Skill
    EditModalSkill(emprecord) {
      this.loading = true;
      console.log("emprecord : ",emprecord);

      this.showCloseButton = true;
      this.showResetButton = false;

      this.addSkill_btn = false;
      this.removeSkill_btn = false;
      this.skills = [{empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0}]
      //Bind Model
      this.skills[0].employeeId = emprecord.employeeId;
      this.skills[0].empSkillId = emprecord.empSkillId;
      this.skills[0].skillLevelOutof100 = emprecord.skillLevelOutof100;
      this.skills[0].skillsSubCategoryId = emprecord.skillsSubCategoryId;
      console.log("employeeid : ",this.skills[0].employeeId)
      console.log("empSkillId : ",this.skills[0].empSkillId)
      console.log("skillLevelOutof100 : ",this.skills[0].skillLevelOutof100)
      console.log("skillsSubCategoryId : ",this.skills[0].skillsSubCategoryId)

      this.employeeId= emprecord.employeeId;
      this.empSkillId= emprecord.empSkillId;
      this.skillsSubCategoryId = emprecord.skillsSubCategoryId;
      this.skillLevelOutof100 = emprecord.skillLevelOutof100;


      this.loading = false;


    },

    addSkill(){
      this.skills.push({empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0})
    },
    removeSkill(index){
      this.skills.splice(index,1);
    },

    AddOrUpdate() {

      //skill Section
      if (this.title == "Edit Skill") {
        console.log("EditTitle : " + this.title);

        this.updateSkills();

      }
      else  {
        console.log("addTitle : " + this.title);

        this.addSkills();
        //this.getAll();
      }


    },

    addSkills(){
      if (
          this.skills[0].skillsSubCategoryId != null &&
          this.skills[0].skillLevelOutof100 != null
      ) {
        this.info = [];
        console.log("emloyeeid : ",this.employeeId)
        //this.skills[0].employeeId = Number(this.employeeId);
        for (let i in this.skills){
          this.skills[i].employeeId = Number(this.employeeId);
          this.skills[i].skillLevelOutof100 = Number(this.skills[i].skillLevelOutof100);
        }

        axios
            .post("/api/EMPSkill/InsertEMPSkills", this.skills)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {
                this.rerender();
                this.skillSectionState(false);
                this.resetPage();
                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.rerender();
                this.skillSectionState(false);
                this.resetPage();
                this.$bvToast.toast("Skill Inserted Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });


              }
            });
      } else {
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
      }
    },
    updateSkills(){
      if (
          this.skills[0].skillsSubCategoryId != null &&
          this.skills[0].skillLevelOutof100 != null
      ) {
        this.info = [];
        console.log("emloyeeid : ",this.skills[0].empSkillId);
        //this.skills[0].employeeId = Number(this.employeeId);
        let model={
          empSkillId:Number(this.skills[0].empSkillId),
          employeeId:Number(this.skills[0].employeeId),
          skillsSubCategoryId:Number(this.skills[0].skillsSubCategoryId),
          skillLevelOutof100:Number(this.skills[0].skillLevelOutof100)
        };

        axios
            .post("/api/EMPSkill/UpdateEmployeeSkill", model)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {
                this.rerender();
                this.skillSectionState(false);
                this.resetPage();
                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.rerender();
                this.skillSectionState(false);
                this.resetPage();
                this.$bvToast.toast("Skill Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });


              }
            });
      } else {
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
      }
    },
    reset() {
      this.skills=[{empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0}];

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
      this.$emit('update-Component-Key', this.componentKey);
    },
    mounted() {
    this.getAll();
    this.getSubCategories();
    this.AddOrEditModal();
  }
  }
  

  

</script>
<style scoped>
#skillbutton{
  width: 9rem !important;
}
@media screen and (max-width: 720px) {
  #skillbutton{
  width: 20rem !important;
}
}
</style>