import { render, staticRenderFns } from "./LabourManPower.vue?vue&type=template&id=3d474198&scoped=true"
import script from "./LabourManPower.vue?vue&type=script&lang=js"
export * from "./LabourManPower.vue?vue&type=script&lang=js"
import style0 from "./LabourManPower.vue?vue&type=style&index=0&id=3d474198&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d474198",
  null
  
)

export default component.exports