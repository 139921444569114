<template>
  <div class="w-100 p-0 m-0" :key="componentKey">
    <div class="custom-modal w3-animate-opacity cpModal" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="custom-row pt-2 pl-3 pr-3">
            <h5 class="modal-title flex-grow-1" id="exampleModalLabel">Notice</h5>

          </div>
          <div class="modal-body">
            <div class="custom-row">
              <span class="mr-4 ml-2">
                          <b-icon
                              icon="exclamation-triangle"
                              variant="danger"
                              scale="2"
                          ></b-icon>
                  </span>
              <div class="flex-grow-1">
                <span> To apply for jobs, please click on the proceed button to Sign In / Sign Up as an Employee. </span>
              </div>

            </div>


            <div class="modal-footer">
              <div>
                <b-button
                    class="form-control form-control-sm custom-form-btn custom-close-btn"
                    type="button"
                    size="sm"
                    variant="secondary"
                    @click="close"

                >
                  Close
                </b-button>
              </div>
              <div>
                <b-button
                    class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                    @click="redirectToSignUp"
                    type="button"
                    size="sm"
                    variant="outline-dark"
                    data-dismiss="modal"
                >
                  Proceed
                </b-button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>

</template>
<script>

import axios from "axios";

export default {

  name:"ConfirmationModal",
  components:{

  },
  data() {
    return {
      info:null,
      componentKey:0,

      url:"",

    };
  },

  props:{
    title:{
      type: String
    },

    expRecord:{
      type: Object
    },
    proRecord:{
      type: Object
    },
    eduRecord:{
      type: Object
    },
    skillRecord:{
      type: Object
    },


  },

  methods:{
    close(){
      this.$emit('toggle-modal');

    },
    resetPage(){
      //this.$emit('reset-page');
      console.log("resettriggerrd")


    },
    // Redirect To SignUp
    redirectToSignUp(){
      this.$router.push(
          { name: 'SignUp'})
      this.$auth.logout();
    },

    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },

  },
  mounted() {

  }
}
</script>
<style scoped>
.cpModal{
  padding: 0 !important;
}
.custom-modal{
  background:rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1072;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}


</style>
