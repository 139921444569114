<template>
  <!-- prettier-ignore -->
  <div class="container">
    <!--content area start-->
    <div class="mt-2">
     

      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="graduate-tab"
                  data-toggle="tab"
                  href="#graduate"
                  role="tab"
                  aria-controls="graduate"
                  aria-selected="true"
              >
                <span> Graduate </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div >
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="graduate"
            role="tabpanel"
            aria-labelledby="graduate-tab"
        >
          <form @reset="onResetModal" v-if="show" autocomplete="off">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3" v-if="availableDate_dd">
                  <label >When You Are Available For Internship?</label>

                  <b-form-datepicker
                      id="availableDate_id"
                      size="sm"

                      class="custom-input-gotopage custom-datepicker"
                      placeholder="Choose Date"
                      v-model="grd.availableDate"

                  ></b-form-datepicker>

                </div>



              </div>

              <div class="col-md-6">
                <div class="mb-3" v-if="workPlacement_dd">
                  <label for="workPlacement_id">In Which Work Placement You Are Interested In?</label>
                  <select id="workPlacement_id"
                          class="custom-select-option form-control form-control-sm"
                          v-model="selectedWorkPlacement"
                  >
                    <option
                        v-for="option in workPlacements"
                        :key="option.graduateWorkPlacementTypeId"
                        :value="option.graduateWorkPlacementTypeId"
                    >
                      {{ option.graduateWorkPlacementTitle }}
                    </option>
                  </select>
                </div>

              </div>

            </div>
            <div class="mb-3" v-if="lookingDescription_input">
              <h4>Remote and Flexible</h4>
              <label class="form-label" for="lookingDescription_id">Describe the project and services you're
                looking for - Please be as detailed as possible:</label>
              <textarea
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="lookingDescription_id"
                  v-model="grd.serviceDescription"
                  rows="5"
                  placeholder="I'm looking for..."
                  required
              />
            </div>

            <div class="row">
              <div class= "col-md-6">
                <div class="mb-3" v-if="category_dd">
                  <label for="category_id">Choose a category:</label>
                  <select id="category_id"
                          class="custom-select-option form-control form-control-sm"
                          v-model="selectedCategory" @change="getSkillsSubCategory($event)"
                  >
                    <option
                        v-for="option in categories"
                        :key="option.skillsCategoryId"
                        :value="option.skillsCategoryId"
                    >
                      {{ option.skillsCategoryTitle }}
                    </option>
                  </select>
                </div>

              </div>
              <div class= "col-md-6">
                <div class="mb-3" v-if="subCategory_dd">
                  <label for="subCategory_id">Choose A Subcategory</label>
                  <select id="subCategory_id"
                          class="custom-select-option form-control form-control-sm"
                          v-model="selectedSubCategory"
                  >
                    <option
                        v-for="option in subCategories"
                        :key="option.skillsSubCategoryId"
                        :value="option.skillsSubCategoryId"
                    >
                      {{ option.skillsSubCategoryTitle }}
                    </option>
                  </select>
                </div>


              </div>
            </div>


            <div class="modal-footer">
              <div>
                <b-button
                    id="resetbtn"
                    class="form-control custom-form-btn custom-btn-gotopage"
                    @click="onResetModal"
                    type="reset"

                    variant="outline-dark"
                >Reset
                </b-button>
              </div>

              <div>
                <b-button
                    class="form-control custom-form-btn custom-btn-gotopage"
                    @click="submitForm"

                    variant="outline-dark"
                >Save
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--content area end-->
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import { ArrowLeftIcon } from "vue-feather-icons";
Vue.use(VueAxios, axios);

//import vSelect from "vue-select";
// import { ModelListSelect } from 'vue-search-select'

// import 'vue-search-select/dist/VueSearchSelect.css'



export default {
  name: "Graduate",
  components: {
    // ArrowLeftIcon,
    // ModelListSelect,
    //vSelect
  },
  data() {
    return {
      apiURL: "",
      currentPage: 1,
      total: 1,

      list: [],
      PageOfItems: [],
      info: [],
      option: 0,
      //bool
      availableDate_dd:true,
      workPlacement_dd:true,
      lookingDescription_input:true,
      category_dd:true,
      subCategory_dd:true,



      selectedWorkPlacement:-1,
      workPlacements:null,
      selectedSubCategory:-1,
      subCategories: null,

      selectedCategory:-1,
      categories:null,

      grd:{
        availableDate: "",
        graduateWorkPlacementTypeId: 0,
        serviceDescription: "",
        skillsCategoryId: 0,
        skillsSubCategoryId: 0
      },





      //form
      show: true,

      loading: true,
      errored: false,

      rowError: false,
      rowid: 0,

      totalCount: 0,
      componentKey: 0,




      //searchable dropdown
      options:[],

    };
  },

  //shows record first time with default values
  mounted() {
    this.getSkillCategories();
    this.getGraduateWorkPlacementType();


  },

  methods: {
    getSkillCategories() {
      this.categories = null;
      axios.get('/api/SkillsCategory/GetSkillsCategorysServices')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.categories = res.data.data;
            console.log("categories : ", this.categories);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },

    getGraduateWorkPlacementType() {
      this.workPlacements = null;
      axios.get('/api/GraduateWorkPlacementType/GetGraduateWorkPlacementTypes')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            res.data
            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.workPlacements = res.data.data;
            console.log("workPlacements : ", this.workPlacements);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //  

              //   solid: true,
              // });
            }
          });

    },
    getSkillsSubCategory(event) {
      this.subCategories = null;
      axios.get('/api/SkillsSubCategorys/GetSkillsSubCategorys/' + event.target.value + ''
      ).then(res => {
        if (res.status != '200') {
          console.log('error.....')
          const error = new Error('Failed to fetch!');
          return error;
        }
        if (res.data != null){
          this.subCategories = res.data.data;
          console.log("subCategories : ",this.subCategories)
        }
        else {
          console.log("Error : Sorry there is no response");
        }

      }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    submitForm(){
      if (
          this.selectedCategory != -1 &&
          this.selectedWorkPlacement != -1 &&
          this.selectedSubCategory != -1
      ) {
        this.info = [];

        this.grd.graduateWorkPlacementTypeId = Number(this.selectedWorkPlacement);
        this.grd.skillsCategoryId = Number(this.selectedCategory);
        this.grd.skillsSubCategoryId = Number(this.selectedSubCategory);




        axios
            .post("/api/EMPGraduates/InsertEMPGraduates", this.emp)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.$bvToast.toast("inserted Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });
                this.$router.push({name:'EmployeeProfile'});

              }
            });
      } else {
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
      }
    },
    //Binding Dropdown
    // onBindDropdown() {
    //   //this.getUserList();
    //   /*this.getManagerList();*/
    //   //this.getClientList();
    // },
    //User DropDown List
    // getUserList() {
    //   this.loading = true;
    //   this.usersDropDown = [];
    //
    //   axios
    //       .get("/User/GetAll")
    //       .then(res => {
    //         console.log("response : " , res.data.status);
    //         if (res.data.status == 1) {
    //           //INSERT ONLY EMPLOYEES NOT CUSTOMERS
    //           /* for (let i = 0; i < res.data.data; i++) {
    //             if(res.data.data[i].role === 201){
    //               continue;
    //             }else{
    //               this.usersDropDown.push(res.data.data[i]) ;
    //               console.log("users are: ");
    //               console.log(res.data.data[i]);
    //             }
    //           }*/
    //
    //           this.usersDropDown = res.data.data;
    //
    //           console.log("drpdn : " + this.usersDropDown);
    //         } else if (res.data.Status == 0) {
    //           this.$bvToast.toast("Sorry there is no response", {
    //             toaster: "b-toaster-top-center",
    //             variant: "danger",
    //             title: "Error",
    //             solid: true
    //           });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    // //Manager DropDown List
    // getManagerList() {
    //   this.loading = true;
    //
    //
    //   console.log("Entered in managerid");
    //   console.log("Role is",this.roleselected)
    //
    //   //PM
    //   if(this.roleselected === "100" || this.roleselected === "104" || this.roleselected === "-1"){
    //     this.employeesBasedOnRoleSelected = 0;
    //     this.selectedManager = 0;
    //     this.managerDropDown = [];
    //     this.mgr_dd=false;
    //   }else if(this.roleselected === "105"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 104;
    //   }else if(this.roleselected === "106"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 105;
    //   }else if(this.roleselected === "103" || this.roleselected === "107" || this.roleselected === "108"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 106;
    //   }else if(this.roleselected === "101"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 103;
    //   }else if(this.roleselected === "102"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 101;
    //   }
    //
    //   axios
    //       .get("/Employee/Get/"+this.employeesBasedOnRoleSelected)
    //       .then(res => {
    //         //   , {
    //         //   params:{
    //         //     role:this.employeesBasedOnRoleSelected
    //         //   }
    //         // }
    //         console.log("response : " + res.data.status);
    //         if (res.data.status == 1) {
    //           this.managerDropDown = res.data.data;
    //
    //           console.log("drpdn : " , this.managerDropDown);
    //         } else if (res.data.Status == 0) {
    //           console.log("Sorry there is no response");
    //           // this.$bvToast.toast("Sorry there is no response", {
    //           //   toaster: "b-toaster-top-center",
    //           //   variant: "danger",
    //           //   title: "Error",
    //           //   solid: true
    //           // });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    // getManagerId(){
    //   //GET MANAGER ID
    //   this.getManagerList();
    //   //ALSO GET DYNAMIC DROPDOWN
    //   this.getDynamicDropdownOnRoleBasis();
    // },
    // getDynamicDropdownOnRoleBasis(){
    //   if(this.roleselected === "105"){
    //     //GET PROJECTS
    //     axios.get("/Project/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }
    //   else if(this.roleselected === "106"){
    //     //GET REGIONS
    //     axios.get("/Region/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.Status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   } else if(this.roleselected === "103" || this.roleselected === "107" || this.roleselected === "108"){
    //     axios.get("/Area/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }else if(this.roleselected === "101"){
    //     axios.get("/SubArea/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }else if(this.roleselected === "102"){
    //     axios.get("/Site/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.Status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }
    // },
    // //Client DropDown List
    // getClientList() {
    //   this.loading = true;
    //   this.clientDropDown = [];
    //
    //   axios
    //       .get("/User/GetAll")
    //       .then(res => {
    //         console.log("response : " + res.data.status);
    //         if (res.data.status == 1) {
    //           this.clientDropDown = res.data.data;
    //
    //           console.log("drpdn : " + this.clientDropDown);
    //         } else if (res.data.Status == 0) {
    //           this.$bvToast.toast("Sorry there is no response", {
    //             toaster: "b-toaster-top-center",
    //             variant: "danger",
    //             title: "Error",
    //             solid: true
    //           });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    //
    //
    //
    //
    // //GET ROLES
    // getRoles(role) {
    //   if (role === 100) {
    //     return "Admin";
    //   } else if (role === 101) {
    //     return "Cluster Owner";
    //   } else if (role === 102) {
    //     return "Site Supervisor";
    //   } else if (role === 103) {
    //     return "Assistant Manager";
    //   } else if (role === 104) {
    //     return "HOD";
    //   } else if (role === 105) {
    //     return "PM";
    //   } else if (role === 106) {
    //     return "RM";
    //   } else if (role === 107) {
    //     return "Grid Lead";
    //   } else if (role === 108) {
    //     return "Team Lead";
    //   }
    // },
    // //GET DEPARTMENTS
    // getDepartment(dep) {
    //   if (dep === 100) {
    //     return "Administration";
    //   } else if (dep === 101) {
    //     return "OSU";
    //   } else if (dep === 102) {
    //     return "Finance";
    //   } else if (dep === 103) {
    //     return "Extra Work";
    //   } else if (dep === 104) {
    //     return "Warehouse";
    //   } else if (dep === 105) {
    //     return "OM";
    //   } else if (dep === 106) {
    //     return "EHS";
    //   }
    // },
    //
    // //Create
    // onCreate() {
    //   if (
    //       this.employeeCode != "" &&
    //       this.fullName != "" &&
    //       this.emailAddress != "" &&
    //       this.password != "" &&
    //       this.roleselected != "-1" &&
    //       this.selectedDepartment != "-1" &&
    //       this.selectedEmployeeType != "-1"
    //   ) {
    //     if (this.password == this.confirmPassword) {
    //       if (
    //           this.password.match(this.pass_validtion) &&
    //           this.confirmPassword.match(this.pass_validtion)
    //       ) {
    //         let model = {
    //           employee:{
    //             employeeId: 0,
    //             employeeCode: this.employeeCode,
    //             department: Number(this.selectedDepartment),
    //             employeeType: this.selectedEmployeeType,
    //             managerId: this.selectedManager,
    //             isDeleted: false,
    //             isActive: true,
    //             userRefId: 0,
    //             user: {
    //               userId: 0,
    //               fullName: this.fullName,
    //               email: this.emailAddress,
    //               emailConfirmed:true,
    //               phoneNumber: this.phoneNo,
    //               lockoutEndDateUtc: "2021-05-03T03:42:00.754Z",
    //               phoneNumberConfirmed:true,
    //               twoFactorEnabled: true,
    //               passwordHash: this.confirmPassword,
    //               accessFailedCount: 0,
    //               securityStamp: "string",
    //               lockoutEnabled: true,
    //               accessEnabled: true,
    //               role: Number(this.roleselected)
    //             },
    //             //clientRefId: this.selectedClient,
    //           }
    //
    //         }
    //
    //         if(this.selectedClient!=0){
    //           model.employee.clientRefId=this.selectedClient;
    //         }
    //
    //         if(this.roleselected==='105'){
    //           model.accessId=this.dynamicItem.projectId;
    //         }else if(this.roleselected==='106'){
    //           model.accessId=this.dynamicItem.regionId;
    //         }else if(this.roleselected === '103' || this.roleselected === '107' || this.roleselected === '108'){
    //           model.accessId=this.dynamicItem.areaId;
    //         }else if(this.roleselected === '101'){
    //           model.accessId=this.dynamicItem.subAreaId;
    //         }else if(this.roleselected==='102'){
    //           model.accessId=this.dynamicItem.siteId;
    //         }
    //
    //         //if(model.employeeType)
    //         console.log("dynamicItem : ", this.dynamicItem);
    //         axios
    //             .post("/Employee/Add", model)
    //             .then(res => {
    //               console.log(res.data);
    //
    //               // $("#addorEditModal").modal("hide");
    //               this.$router.push({ name: "Employees" });
    //             })
    //             .catch(error => {
    //               console.log(error);
    //               this.errored = true;
    //             })
    //             .finally(() => {
    //               if (this.errored) {
    //                 this.$bvToast.toast("Employee Added Successfully ", {
    //                   toaster: "b-toaster-top-center",
    //                   variant: "success",
    //
    //                   solid: true
    //                 });
    //                 console.log("Sorry there is no response");
    //                 //   this.getAll();
    //                 // this.$bvToast.toast("Sorry there is no response ", {
    //                 //   toaster: "b-toaster-top-center",
    //                 //   variant: "danger",
    //
    //                 //   solid: true
    //                 // });
    //               } else {
    //                 console.log("Employee Added Successfully");
    //                 //this.getAll();
    //                 this.$bvToast.toast("Employee Added Successfully ", {
    //                   toaster: "b-toaster-top-center",
    //                   variant: "success",
    //
    //                   solid: true
    //                 });
    //               }
    //             });
    //       } else {
    //         this.$bvToast.toast(
    //             "password must be between 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter",
    //             {
    //               toaster: "b-toaster-top-center",
    //               variant: "danger",
    //
    //               solid: true
    //             }
    //         );
    //       }
    //     } else {
    //       this.$bvToast.toast("Passwords did not match", {
    //         toaster: "b-toaster-top-center",
    //         variant: "danger",
    //
    //         solid: true
    //       });
    //     }
    //   } else {
    //     this.$bvToast.toast("Please Fill all required fields", {
    //       toaster: "b-toaster-top-center",
    //       variant: "danger",
    //
    //       solid: true
    //     });
    //   }
    // },
    // //Reset
    onResetModal() {
      this.grd = {
        availableDate: new Date(),
        graduateWorkPlacementTypeId: 0,
        serviceDescription: "",
        skillsCategoryId: 0,
        skillsSubCategoryId: 0
      };

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //
    //
    //
    // //searchable dropdown
    // getData(){
    //   axios.get("/Site/GetAll").then((res) => {
    //     console.log("response : "+res.data.status);
    //     if (res.data.status == 1) {
    //       this.options = res.data.data;
    //       console.log(this.list);
    //     } else if (res.data.Status == 0) {
    //
    //       this.$bvToast.toast("Sorry there is no response", {
    //         toaster: "b-toaster-top-center",
    //         variant: "danger",
    //         title:"Error",
    //         solid: true,
    //       });
    //     }
    //
    //   })
    // },
    // codeAndNameAndDesc (item) {
    //   return `${item.siteId} - ${item.siteName}`
    // },
    // reset1 () {
    //   this.dynamicItem = {}
    // },
    // selectFromParentComponent1 () {
    //   // select option from parent component
    //   this.dynamicItem = this.options[0]
    // },
  }
};
</script>

<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* .custom-nav-tabs {
 border-bottom: 3px solid #19aa4b !important; 
} */

.custom-nav-tabs .custom-nav-link:hover {
  color: black;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-color: #EA216C;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>
