

<template>
  <header  class="custom-mainmenu" :class="[!$auth.check() ? 'sticky-top':'', $auth.check() ? '':'']">
    <div class="custom-header">
      <div class="">
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light">


          <div
              class="navbar-collapse custom-multi-navbar"
              id="headerSupportedContent"
          >
            <div class="navbar mr-auto w-100 pl-0 pr-0">
              <div  class="navbar-nav mr-auto w-100">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" :to="{ name: 'Home'}">
                      Home
                    </router-link
                    >
                  </li>
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" :to="{ name: 'AllJobs'}">
                      Job Board
                    </router-link>
                  </li>
                  <!--                <template v-if="$auth.check()">
                                    <li v-if="$auth.user().user.role == 'Employer'" class="nav-item mr-2">
                                      <router-link
                                          class="nav-link custom-menu-link py-1 px-0 mr-3"
                                          :to="{ name: 'EmployerDashboard' }"
                                      >
                                        Employer
                                      </router-link>
                                    </li>
                                    <li v-else class="nav-item mr-2">
                                      <a href="javascript:void (0)"
                                          class="nav-link custom-menu-link py-1 px-0 mr-3"
                                          @click="navigateToLogin"
                                      >
                                        Employer
                                      </a>
                                    </li>
                                  </template>
                                  <template v-else>

                                    <li class="nav-item mr-2">
                                      <router-link
                                          class="nav-link custom-menu-link py-1 px-0 mr-3"
                                          :to="{ name: 'SignIn' }"
                                      >
                                        Employer
                                      </router-link>
                                    </li>
                                  </template>-->
                  <li class="nav-item mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3" href="https://www.ventureher.com/#/" target="_blank">
                      Venture Her
                    </a>
                  </li>
                  <li class="nav-item mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3" href="https://www.hecancy.com/#/" target="_blank">
                      Wecancy
                    </a>
                  </li>
                  <li class="nav-item mr-2">
                    <a class="nav-link py custom-menu-link py-1 px-0 mr-3" href="https://www.viilearn.com/#/" target="_blank">
                      Viilearn
                    </a>
                  </li>
                  <li class="nav-item mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3" href="https://www.harshehar.com/#/" target="_blank">
                      Har Shehar
                    </a>
                  </li>
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/about-us">
                      About Us 
                    </router-link>
                  </li>
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/contact-us">
                       ContactUs
                    </router-link>
                  </li>
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/Feedback">
                      Feedback
                    </router-link>
                  </li>
                </ul>
                <div class="">
                  <div class="input-group custom-search-input">
                    <div class="input-group-prepend">
                    <span
                        class="input-group-text custom-input-group-icon"
                        id="basic-addon1"
                    ><b-icon icon="Search"></b-icon
                    ></span>
                    </div>
                    <input
                        type="text"
                        class="form-control custom-filter-input form-control-sm"
                        v-model="jobSearchByNameOrCity.jobTitle"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        @keydown.enter.prevent="setSearchString()"
                    />
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div></div>
        </nav>

      </div>
    </div>

  </header>
</template>

<script>
// var sidebar = document.getElementById("mySidebar");

import axios from "axios";
export default {
  name: "MainMenu",
  components:{

  },
  data(){
    return{
      status:0,



      url:axios.defaults.baseURL,

      jobSearchByNameOrCity:{
        jobTitle: "",
        cityId: null
      },


    };

  },
  computed:{

  },
  mounted(){

  },



  methods: {


    setSearchString(){
      // console.log("type", this.$route.name)

      if(this.$route.name == "AllJobs"){
        /*this.$router.push(
            { name: 'AllJobs', params: { searchString: this.jobTitle }
            })*/
        this.getJobsByName(this.jobSearchByNameOrCity);
      }
      else {

        this.$router.push(
            { name: 'AllJobs'})
        this.getJobsByName(this.jobSearchByNameOrCity);


      }

    },
    getJobsByName(jobFilter){
      console.log("jobFilter : ", jobFilter)
      this.$store.dispatch("getJobsByNameAndCityId", jobFilter)
      //this.$router.push({name: 'AllJobs', params: { jobTitle }})
    },



  },
};
</script>

<style>
/*#ad-banner-id{
  transition: all 0.2s ease-in-out;
  display: block;
}
#ad-banner-id.active{
  display: none;
  transition: all 0.2s ease-in-out;
}*/
</style>
