<template>
  <div style="background: white;" class="jumbotron text-center col-lg-12">
    <div>
<!-- <h2>About Us</h2> -->
      
<img style="width:90%" src="../../assets/aboutus.png"/>
    <!-- <p style="text-align: left; margin-top: 5%; font-size: larger;">
      We are the one stop shop providing you with the productive ecosystem to uplift and empower Pakistani women. We understand the struggles of the women's community of Pakistan which is why we aim to reduce them. Shemeanswork has brought the evolutionary vision into reality and started this initiative with the collaborative efforts of our team. 
</p>
 
<p style="text-align: left; font-size: larger;">
  This is a leap forward that will help the female masses to get career opportunities for years to come. We run an extensive network of providing a productive ecosystem that will help you to get ahead in your professional life. We find the latest jobs in Pakistan and ensure 100% reliability to the women of our country. We aim to bring the evolution that is much needed in Pakistan which is why we work hard to give you the one shop stop that you are looking for. Avail the different opportunities specially designed to empower women at Shemeanswork. We help to empower women to bring action to their dreams and run their businesses easily with the help of Ventureher. This will help to upscale business & entrepreneurial women to their maximum potential through different programs & financing. Get to know about a variety of upcoming events and deals on HarShehar. Knowledge is the key to success and we will help you avail exceptional courses from our ViiLearn E-academy and provide you with the practical set of skills for your future. We believe in equality therefore, we present Hecancy dedicated to the male community of our country.
</p>
 
<p style="text-align: left; font-size: larger;"> 
  We consider the hardships and struggles of the mothers back to work, the lack of fresh graduate jobs in Pakistan, and the Hurdles of PWDs. Seek the latest job opportunities according to your interest from the best job portal in Pakistan. Shemeanswork will be the kickstart for your career that you need right now.
</p> -->
 
</div>
<img style="width:100%" src="../../assets/ceo.png"/>
<!-- <div class="row" style="margin-top:5rem">
 
  <div class="col-lg-4" >
    <img id="mydiv" src="../../assets/images/LUBNABHAYAT.jpg"/>
  </div>
  <div class="col-lg-8">
<p style="text-align: left; font-size: larger;">
  I, Lubna Bhayat, am the CEO of Shemeanswork and an ex-president of the Women Chamber of Commerce Lahore division, Ex-banker, and Co-founder at Finlab Solutions Pvt Ltd. I realized the need for an initiative of an environment that empowers women's capabilities and, this is how Shemeanswork became a reality. I believe that with this approach, we will be able to ease the struggles, hardships and obstacles that women face in their daily lives. I aim to change the lives of Pakistani women by providing them with an exceptional growth environment for their different learning, growing and working needs. To meet these needs, we have collaborated with the best industry players in all fields to get the breakthrough we are looking for. I believe in my team and my dream to reshape the future of countless women in the best ways possible.

</p>
  </div>
</div> -->
  </div> 

  
</template>
<script>
export default {
  name:"AboutUs"
}
</script>
<style scoped>
#mydiv{
  width:80%;
  border-radius: 0 3rem 0 5rem;
  animation: mymove 6s infinite;
}
@keyframes mymove {
  50% {box-shadow: 20px 35px 45px #555555;}
}

</style>