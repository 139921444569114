<template>
  <div class="container">
    <div class="row mt-30" v-if="isDataFetched"  >

      <div class="  col-md-3 ">
        <div class="">




          

          <div class="card p-0 mb-20 w3-center custom-profile-card h-100">
            <div class="card-body pt-4 pb-4">

              <div>

                <div class="custom-profile-image-wrap custom-company-image-wrap" >
                  <div v-if="body.companyLogoAddress !== null">
                    <img :src="url+body.companyLogoAddress" alt="" class="rounded-circle custom-profile-image">

                  </div>
                  <div v-else>
                    <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                  </div>



                </div>

              </div>
              <div class=" w3-center custom-profile-detail">
                <h5 class="font-weight-bold text-capitalize">

                  {{ body.companyName }}

                </h5>



              </div>



            </div>
            <div class="card-footer border-0 pl-0 pr-0">
              <div class="custom-profile-contacts custom-profile-detail">
                <div class="">
                  <a class="text-decoration-none w3-hover-text-light-gray" :href="phoneNumberLink+body.mobileNumber">
                    <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>

                    <span class="">{{ body.mobileNumber }}</span>
                  </a>


                </div>

                <div class=" ">
                  <a class="text-decoration-none w3-hover-text-light-gray" :href="emailLink+body.email">
                    <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                    <span>{{ body.email }}</span>
                  </a>
                </div>
              </div>
              <div class="custom-row justify-content-center mt-2 ">
                <a :href="'https://'+body.facebookLink" target="_blank" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                  <i class="fab fa-facebook-f"></i>
                </a>

                <a :href="'https://'+body.googlePlusLink" target="_blank" class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link">
                  <i class="fab fa-google"></i>
                </a>
                <a :href="'https://'+body.linkedInLink" target="_blank" class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a :href="'https://'+body.twitterLink" target="_blank" class="custom-twitter-icon custom-icon-style custom-social-link">
                  <i class="fab fa-twitter"></i>
                </a>

              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-9  ">
        <div class="w3-card p-3 mb-20">
        <h3>About Us</h3>
          <p class="text-muted text-justify">
            {{body.companyDesciption}}
          </p>
          <div class="row text-muted">
            <div class="col-sm-10">
              <div class="">
                <a class="text-decoration-none w3-hover-text-dark-gray" :href="phoneNumberLink+body.mobileNumber">
                  <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>

                  <span class="">{{ body.mobileNumber }}</span>
                </a>


              </div>

              <div class=" ">
                <a class="text-decoration-none w3-hover-text-dark-gray" :href="emailLink+body.email">
                  <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                  <span>{{ body.email }}</span>
                </a>
              </div>
            </div>
            <div class="col-sm-2">
              <div v-if="$auth.user().user.role == 'Employee'" class="ml-auto">
                <div v-if="followStatus === false">
                  <button class="btn btn-primary btn-apply" @click="onFollowUnfollowCompany(followStatus)">+ Follow</button>
                </div>
                <div v-else>
                  <button class="btn btn-primary btn-apply" @click="onFollowUnfollowCompany(followStatus)">- Unfollow</button>
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>


    </div>

     <div v-if="$auth.user().user.role == 'Employer'" class="row ">
      <div class="col-md-12  ">


         <!-- <div class="w3-card openjobs-public p-3">
        <h3>Open Jobs </h3>
          <a href="#"> <p> Job 1 </p>
          <button class="btn btn-primary right"> Apply</button>
          </a>
          
          <hr>
          <a href="#"> <p> view all </p></a>

      </div> -->

                  <div
              class="w3-card-2 pt-3 pb-3 ml-auto mr-auto"
              style=" " v-if="isDataFetched"
            >
              <header class="w3-container custom-row">
                <h3>Jobs</h3>

                <!-- <router-link
                  to="/ "
                  class="btn btn-outline-secondary ml-auto"
                  >View All</router-link> -->
              </header>
              <hr />
              <custemp v-for="job in employerObj.jobs.slice().reverse()" :key="job">
              <div  class="w3-container custom-row">

                <div>
                  <p class="font-weight-bold w3-text-pink m-0">{{job.jobTitle}}</p>
                  <span>{{job.jobDetail}}</span>
                   <p class="font-weight-bold text-black m-0">{{ job.insertionDate | moment }}  </p>
                </div>

                <div class="ml-auto">

                   <!-- <router-link
                  to="/ "
                  class="btn btn-outline-secondary ml-auto"
                  >Apply</router-link> -->
                </div>

              </div>
              <hr />
              </custemp>

            </div>
      </div>

    </div>

    <div class="style-spacer"></div>
  </div>
</template>


<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "AboutUs",  components: {},
  data() {
    return {

      url:axios.defaults.baseURL,
      has_error: false,
      isDataFetched : false,
      isLoading: false,
      EmployerId: this.$auth.user().employeeId,


      employerObj: null,

      phoneNumberLink: "tel:",
      emailLink: "mailto:",
      followStatus:true,
      followId:0,
      followCompanyModel:{
        id: 0,
        companyId: 0,
        employeeId: 0,
        status: true
      },


      body: {
        employerCompanyId: 0,
        companyEmail: null,
        companyName: null,
        ceoName: null,
        employeeId: this.$auth.user().employeeId,
        industryId: 0,
        ownerShipTypeId: 0,
        companyDesciption: null,
        companyLogoAddress:"",
        countryId: 106,
        cityId: 0,
        address: null,
        establishedInYear: 0,
        websiteUrl: null,
        contactNumber: null,
        googleMapIframe: null,
        linkedInLink: "",
        facebookLink : "",
        googlePlusLink: "",
        twitterLink : "",
        fullName : "",
        email : "",
        mobileNumber : null,

        designation:"",
      },
      //Employee Company Detail
      companyId:0,
    };
  },
  computed: {

  },
  mounted() {
    // /EmployerCompany/GetEmployerCompany
    this.companyId = this.$route.params.id
    console.log("Company Detail: ", this.companyId)

      this.getProfileInfo(this.companyId)




  },
  methods: {
    //   formatDate(date) {
    //   return moment(date).format("Do MMM YYYY")
    // },
     getProfileInfo(id){

       let url = "";
       if (id == 0){

         url = "/api/EmployerCompany/GetEmployerCompany";

       }
       else {

         url = "/api/EmployerCompany/GetAllCompanyById?Id="+id;

       }
       axios.get(url)
           .then(res =>{
             if (res.status != '200') {
               console.log('error.....')
               const error = new Error('Failed to fetch!');
               return error;
             }


             let resdata = res.data.data;
             console.log("res.data : ",  res.data.data);


             if(res.data != 0){
               this.employerObj = resdata
               this.body.employerCompanyId = resdata.employerCompanyId
               this.body.industryId = resdata.industryId
               this.body.cityId = resdata.cityId
               this.body.ownerShipTypeId = resdata.ownerShipTypeId
               this.body.countryId = resdata.countryId
               this.body.establishedInYear = resdata.establishedInYear
               this.body.companyEmail = resdata.companyEmail
               this.body.companyName = resdata.companyName
               this.body.companyLogoAddress = resdata.companyLogoAddress;

               this.body.ceoName = resdata.ceoName
               this.body.companyDesciption = resdata.companyDesciption
               this.body.address = resdata.address
               this.body.websiteUrl = resdata.websiteUrl
               this.body.contactNumber = resdata.contactNumber
               this.body.googleMapIframe = resdata.googleMapIframe

               this.body.linkedInLink = resdata.linkedInLink;
               this.body.facebookLink = resdata.facebookLink;
               this.body.twitterLink = resdata.twitterLink;
               this.body.googlePlusLink = resdata.googlePlusLink;

               this.body.fullName = resdata.employee.fullName
               this.body.designation = resdata.employee.designation;
               this.body.mobileNumber = resdata.employee.mobileNumber;
               this.body.email = resdata.employee.email;
               this.followStatus = resdata.followStatus;
               this.followId = (resdata.id == 0 || resdata.id == null) ? 0: Number(resdata.id)

             }
             else {

             }
             this.isDataFetched = true

             // });
           }).catch(error => {
         console.log(error);
       })
           .finally(() => {

           });


    },

    onFollowUnfollowCompany(fstatus){

      console.log("fstatus", fstatus);


      if(fstatus){
        this.followStatus = false;
      }
      else {
        this.followStatus = true;
      }


      let url = "";

      if(this.followId == 0){
        url = "/api/EmployerCompany/AddCompanyStatus";
      }
      else {
        url = "/api/EmployerCompany/UpdateCompanyStatuss";
      }



      this.followCompanyModel = {
        id: Number(this.followId),
        companyId: Number(this.body.employerCompanyId),
        employeeId: Number(this.body.employeeId),
        status: Boolean(this.followStatus)
      }




      axios.post(url, this.followCompanyModel)
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            //this.featuredJobs = res.data.data;
            console.log("follow job Response : ", res.data.data);
            //this.getCompanies();
            console.log("this.companyId", this.companyId)
            this.getProfileInfo(this.companyId);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {
              this.getCompanies();
              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).fromNow();
    }
  },
};

</script>
