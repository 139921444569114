<template>
  <div class="container">
    <!--content area start-->
    <div class="mt-2">


      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="mumsReturnToWork-detail-tab"
                  data-toggle="tab"
                  href="#mumsReturnToWork-detail"
                  role="tab"
                  aria-controls="mumsReturnToWork-detail"
                  aria-selected="true"
              >
                <span> Mums Return To Work </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="mumsReturnToWork-detail"
            role="tabpanel"
            aria-labelledby="mumsReturnToWork-detail-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h2></h2>


                    <h5 class="card-title">What would describe your current employment status?</h5>
                    <p class="card-subtitle mb-2 text-muted">Select one that applies </p>
                    <div class="container">

                      <div class="row mb-3 mt-4 text-center">

                        <div class="col-md-6">
                          <b-form-radio value="1" id="empStatus_id1" class="custom-radio-btn" v-model="empStatus" name="empStatus-radio-button1" button button-variant="outline-primary">
                            Working for a company full-time
                          </b-form-radio>


                        </div>
                        <div class="col-md-6">
                          <b-form-radio value="2" id="empStatus_id2" class="custom-radio-btn" v-model="empStatus" name="empStatus-radio-button1" button button-variant="outline-primary">
                            Working for a company part-time
                          </b-form-radio>

                        </div>
                      </div>
                      <div class="row mb-3 mt-4 text-center">
                        <div class="col-md-6">
                          <b-form-radio value="3" id="empStatus_id3" class="custom-radio-btn" v-model="empStatus" name="empStatus-radio-button1" button button-variant="outline-primary">
                            Freelancing or Working on temporary projects
                          </b-form-radio>

                        </div>
                        <div class="col-md-6">
                          <b-form-radio value="4" id="empStatus_id4" class="custom-radio-btn" v-model="empStatus" name="empStatus-radio-button1" button button-variant="outline-primary">
                            Not currently working
                          </b-form-radio>

                        </div>
                      </div>
                    </div>
                    <div class="mt-4 mb-3">
                      <h5 class="card-title">Career Pause</h5>
                    </div>

                    <div class="row mb-3 mt-4">


                      <div class="col-md-6">
                        <div class="mb-3 form-group row no-gutters" v-if="startMonth_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="startMonth_id">Start Month</label>
                          <div class="col-sm-9">
                            <select id="startMonth_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedStartMonth"
                            >

                              <option
                                  v-for="option in months"
                                  :key="option.value"
                                  :value="option.value"
                              >
                                {{ option.text }}
                              </option>
                            </select>
                          </div>

                        </div>


                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 form-group row no-gutters" v-if="startYear_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="startYear_id">Start Year</label>
                          <div class="col-sm-9">
                            <select id="startYear_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedStartYear"
                            >
                              <option value="-1">Select Year</option>
                              <option
                                  v-for="(option, index ) in yearList"
                                  :key="index"
                                  :value="option"
                              >
                                {{ option }}
                              </option>
                            </select>
                          </div>

                        </div>

                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 form-group row no-gutters" v-if="endMonth_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="endMonth_id">End Month</label>
                          <div class="col-sm-9">
                            <select id="endMonth_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedEndMonth"
                            >

                              <option
                                  v-for="option in months"
                                  :key="option.value"
                                  :value="option.value"
                              >
                                {{ option.text }}
                              </option>
                            </select>
                          </div>

                        </div>

                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 form-group row no-gutters" v-if="endYear_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="endYear_id">End Year</label>
                          <div class="col-sm-9">
                            <select id="endYear_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedEndYear"
                            >
                              <option value="-1">Select Year</option>
                              <option
                                  v-for="(option, index ) in yearList"
                                  :key="index"
                                  :value="option"
                              >
                                {{ option }}
                              </option>
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3" v-if="description_input">
                          <label class="" for="description_id">Description</label>
                          <div class="">
                <textarea
                    type="text"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="description_id"
                    v-model="description"
                    placeholder="Description"
                    required
                    maxlength="1000"
                />
                          </div>

                        </div>
                      </div>
                    </div>

                    <h5 class="card-title">How many hours you can work?</h5>
                    <p class="card-subtitle mb-2 text-muted">Select one that applies </p>
                    <div class="container">

                      <div class="row mb-3 mt-4 text-center">

                        <div class="col-md-6">
                          <b-form-radio value="1" id="workHour_id1" class="custom-radio-btn" v-model="workHour" name="work-hour-radio-button1" button button-variant="outline-primary">
                            1-20 hours per week

                          </b-form-radio>


                        </div>
                        <div class="col-md-6">
                          <b-form-radio value="2" id="workHour_id2" class="custom-radio-btn" v-model="workHour" name="work-hour-radio-button2" button button-variant="outline-primary">
                            21-30 hours per week
                          </b-form-radio>

                        </div>
                      </div>
                      <div class="row mb-3 mt-4 text-center">
                        <div class="col-md-6">
                          <b-form-radio value="3" id="workHour_id3" class="custom-radio-btn" v-model="workHour" name="work-hour-radio-button3" button button-variant="outline-primary">
                            31-40 hours per week

                          </b-form-radio>

                        </div>
                        <div class="col-md-6">
                          <b-form-radio value="4" id="workHour_id4" class="custom-radio-btn" v-model="workHour" name="work-hour-radio-button4" button button-variant="outline-primary">
                            40 hours per week
                          </b-form-radio>

                        </div>
                      </div>
                    </div>
                    <h5 class="card-title">Where can you work?</h5>
                    <p class="card-subtitle mb-2 text-muted">Select one that applies </p>
                    <div class="container">

                      <div class="row mb-3 mt-4 text-center">

                        <div class="col-md-6">
                          <b-form-radio value="1" id="workHere_id1" class="custom-radio-btn" v-model="workWhere" name="work-here-radio-button1" button button-variant="outline-primary">
                            At the office
                          </b-form-radio>


                        </div>
                        <div class="col-md-6">
                          <b-form-radio value="2" id="workHere_id2" class="custom-radio-btn" v-model="workWhere" name="work-here-radio-button2" button button-variant="outline-primary">
                            Remote
                          </b-form-radio>

                        </div>
                      </div>
                      <div class="row mb-3 mt-4 text-center">
                        <div class="col-md-6">
                          <b-form-radio value="3" id="workHere_id3" class="custom-radio-btn" v-model="workWhere" name="work-here-radio-button3" button button-variant="outline-primary">
                            Combination

                          </b-form-radio>

                        </div>
                        <div class="col-md-6">
                          <b-form-radio value="4" id="workHere_id4" class="custom-radio-btn" v-model="workWhere" name="work-here-radio-button4" button button-variant="outline-primary">
                            Unsure right now
                          </b-form-radio>

                        </div>
                      </div>
                    </div>

                    <h5 class="card-title">Travel Preferences</h5>
                    <p class="card-subtitle mb-2 text-muted">How for are you willing to commute?(in miles)</p>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3 " v-if="miles_dd">
                          <!--                    <label class="col-sm-3 col-form-label col-form-label-sm" for="miles_id">Miles</label>-->
                          <div class="">
                            <select id="miles_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedMiles"
                            >
                              <option value="-1">Select Miles</option>
                              <option
                                  v-for="(option, index ) in miles"
                                  :key="index"
                                  :value="option.value"
                              >
                                {{ option.text }}
                              </option>
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!--              <h4 class="card-title">Travel Preferences</h4>-->
                    <p class="card-subtitle mb-2 text-muted">What percentage of time can you travel out of
                      town?</p>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3 " v-if="travelPercent_dd">
                          <!--                    <label class="col-sm-3 col-form-label col-form-label-sm" for="miles_id">Miles</label>-->
                          <div class="">
                            <select id="travelPercent_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedTravelPercent"
                            >
                              <option value="-1">Select Miles</option>
                              <option
                                  v-for="(option, index ) in travels"
                                  :key="index"
                                  :value="option.value"
                              >
                                {{ option.text }}
                              </option>
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--content area end-->

  </div>
</template>
<script>
export default {
  name:"MumsReturnToWork",
  data(){
    return{
      apiURL: "",
      currentPage: 1,
      total: 1,

      //bool
      startMonth_dd:true,
      endMonth_dd:true,
      startYear_dd:true,
      endYear_dd:true,
      miles_dd:true,
      travelPercent_dd:true,


      empStatus:"",
      workHour:"",
      workWhere:"",
      description_input:true,
      yearList:[],
      selectedStartMonth:"-1",
      selectedEndMonth:"-1",
      selectedStartYear:"-1",
      selectedEndYear:"-1",
      description:"",
      months:[
        { value: "-1", text:"Select Month" },
        { value: "1", text:"1" },
        { value: "2", text:"2" },
        { value: "3", text:"3" },
        { value: "4", text:"4" },
        { value: "5", text:"5" },
        { value: "6", text:"6" },
        { value: "7", text:"7" },
        { value: "8", text:"8" },
        { value: "9", text:"9" },
        { value: "10", text:"10" },
        { value: "11", text:"11" },
        { value: "12", text:"12" }

      ],
      selectedMiles:"-1",
      miles:[
        {value : "1", text: "0-5"},
        {value : "2", text: "5-10"},
        {value : "3", text: "10-20"},
        {value : "4", text: "20-30"},
        {value : "5", text: "30-40"},
        {value : "6", text: "40-50"},
        {value : "7", text: "50-60"},
        {value : "8", text: "60-70"},
        {value : "9", text: "70-80"},
        {value : "10", text: "80-90"},
        {value : "11", text: "90-100"},
        {value : "12", text: "More than 100"}
      ],
      selectedTravelPercent:"-1",
      travels:[
        {value : "0", text: "not at all"},
        {value : "1", text: "5"},
        {value : "2", text: "10"},
        {value : "3", text: "20"},
        {value : "4", text: "30"},
        {value : "5", text: "40"},
        {value : "6", text: "50"},
        {value : "7", text: "60"},
        {value : "8", text: "70"},
        {value : "9", text: "80"},
        {value : "10", text: "90"},
        {value : "11", text: "100"}

      ],
      list: [],
      PageOfItems: [],
      info: [],
      option: 0,
      //form
      show: true,

      loading: true,
      errored: false,

      rowError: false,
      rowid: 0,

      totalCount: 0,
      componentKey: 0,




      //searchable dropdown
      options:[],
    };

  },
  methods:{
    getYears() {
      var startYear = 2000;
      var i = 0;
      for (i = startYear; i <= new Date().getFullYear(); i++) {
        this.yearList.push(i);
      }
      console.log("year List: ",this.yearList)
    },
    onResetModal(){
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  mounted() {
    this.getYears();
  }


}
</script>
<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}



.custom-nav-tabs .custom-nav-link:hover {
  color: #242424 !important;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000 !important;
  background-color: #fff;
  border-color: #EA216C;
  opacity: 1;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>